







































import { Component, Vue } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";

@Component({
  metaInfo: {
    title: "Açık Burs Başvuruları"
  }
})
export default class OpenAppsView extends Vue {
  scholarships: Scholarship[] = [] as Scholarship[];

  async getScholarships() {
    const qs = await firebase
      .firestore()
      .collection("/apps/kahev-org/scholarships")
      .where("disabled", "==", false)
      .orderBy("title")
      .get();

    if (!qs.empty) {
      qs.docs.forEach(doc => {
        this.scholarships.push({
          id: doc.id,
          title: doc.data().title,
          urlCode: doc.data().urlCode
        });
      });
    }
  }

  async mounted() {
    this.getScholarships();
  }
}
